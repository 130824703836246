<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> Penyesuaian List </CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top: 27px"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add"
										id="btn"
										color="success"
										style="margin-top: 27px; margin-left: 5px"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height: 900px">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-product" slot-scope="props">
									{{ props.rowData.productItem.product.nama }}<br />
									{{ props.rowData.productItem.size }}
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<CRow>
				<CCol md="12">
					<CInput
						label="Kode Barang"
						v-model="frm.kode"
						placeholder="P0386.PD00002.120.I"
					/>
				</CCol>
				<CCol>
					<label class="text-info">
						{{ productSelected.nama + " - " + productSelected.kode }}
					</label>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="6">
					<CInput
						label="Jumlah"
						type="number"
						v-model.number="frm.jumlah"
						placeholder="jumlah"
					/>
				</CCol>
				<CCol md="6">
					<div role="group" class="form-group">
						<label for="type" class=""> Type </label>
						<select v-model="frm.type" id="type" class="form-control">
							<option data-key="addition" value="addition">addition (+)</option>
							<option data-key="subtraction" value="subtraction">
								subtraction (-)
							</option>
						</select>
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<CInput label="Catatan" v-model="frm.catatan" placeholder="note" />
				</CCol>
			</CRow>

			<template #header>
				<h6 class="modal-title">Add new data</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CANCEL</CButton>
				<CButton @click="save" :disabled="loading" color="success"
					>SAVE</CButton
				>
			</template>
		</CModal>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";

export default {
	data() {
		return {
			uri: basuri + "adjustment",
			moreParams: { filter: null, t_: Date.now() },
			selectedId: null,
			loading: false,
			modal: false,
			frm: {
				kode: "",
				type: "subtraction",
				jumlah: 1,
				catatan: "",
			},
			productSelected: { nama: "none", kode: "none" },
			fields: [
				{
					name: "productItem.kode",
					title: "Kode Item",
					sortField: "productItem.kode",
				},
				{
					name: "slot-product",
					title: "Product",
					//sortField: "productItem.product.nama",
				},
				{
					name: "jumlah",
					title: "Jumlah",
					sortField: "jumlah",
				},
				{
					name: "catatan",
					title: "Catatan",
					sortField: "catatan",
				},
				{
					name: "type",
					title: "Type",
					sortField: "type",
				},
				{
					name: "createdAt",
					title: "Tanggal",
					sortField: "createdAt",
				},
			],
		};
	},
	watch: {
		"moreParams.filter": debounce(function (val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
		"frm.kode": debounce(function (val) {
			this.productSelected = { nama: "none", kode: "none" };
			if (val.length >= 18) this.getProduct();
		}, 400),
	},
	methods: {
		reset() {
			this.moreParams = { filter: null, t_: Date.now() };
			this.$refs.vuetable.refresh();
		},
		add(data = null) {
			this.frm = {
				kode: "",
				type: "subtraction",
				jumlah: 1,
				catatan: "",
			};

			this.modal = true;
		},
		getProduct: debounce(async function () {
			let datar = {};
			const { data } = await this.$http.get("product-item", {
				params: { filter: this.frm.kode },
			});
			datar = data.data.data[0];
			this.productSelected = { nama: datar.product.nama, kode: datar.kode };
		}, 500),
		save: debounce(async function () {
			this.loading = true;

			try {
				let datar = {};

				const { data } = await this.$http.post("adjustment", this.frm);
				datar = data;

				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.$refs.vuetable.refresh();
					this.modal = false;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loading = false;
		}, 1000),
	},
};
</script>
